import React from 'react';
import './styles.css';

let clicks = 0;
let lastClick = 0;

const SendEmailForm = ({onSubmitButtonClick, onValueChanged, showResult, result}) => {
    let desktopPlatforms = ["Win", "Mac", "X11", "Linux"];

    function isAndroidBrowser() {
        return navigator.userAgent.match(/Android/i);
    }

    function isIOSBrowser() {
        return navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
    }

    const isDesktopBrowser = () => {
        for (let os of desktopPlatforms){
            if (navigator.userAgent.indexOf(os) !== -1){
                return true;
            }
        }

        return false;
    };

    const onFormSubmit = (e) => {
        if(showResult){
            return;
        }

        e.preventDefault();
        e.target.reset();
        onSubmitButtonClick(e);
    };

    const onButtonClick = (e) => {
        const canGo = isAndroidBrowser() || isIOSBrowser();
        if(!canGo && isDesktopBrowser()){
            return;
        }
        
        const delta = window.performance.now() - lastClick;
        lastClick = window.performance.now();
        if(delta < 100){
            clicks++;
        } else {
            clicks = 0;
        }
        
        if(clicks === 3){
            clicks = 0;
            window.location.href = 'https://demo.carryairs.com';
        }
    };

    return (
        <div className="send-email-form-container">
            <p className={'send-email-form-header'}>Let's fly away!</p>
            <form onSubmit={onFormSubmit.bind(this)}>
                <input type="text" placeholder={'Full Name'} name={'name'} id={'name'} className={'send-email-form-input'} onChange={onValueChanged}/>
                <input type="email" placeholder={'Email'} name={'email'} id={'email'} className={'send-email-form-input'} onChange={onValueChanged}/>
                <div className={'captcha'}><div className="g-recaptcha" data-sitekey="6Lf70NEUAAAAAGL3Fd9PUYviSb0N-ovNqVFlHxfL" /></div>
                <button type={'submit'} className={(showResult) ? 'send-email-form-button show' : 'send-email-form-button'} onTouchStart={onButtonClick.bind(this)} onClick={onButtonClick.bind(this)}><span>Submit Interest</span>
                    <div className={(showResult) ? (result) ? "checkmark" : "x-mark" : ""}/></button>
            </form>
        </div>
    );
};

export default SendEmailForm;
