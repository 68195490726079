import React from 'react';
import './styles.css';
import CarryAirLogo from "../CarryAirLogo";
import SendEmailForm from "../SendEmailForm";
import plane from "./plane.png";
import bottomPartMobile from './bottomPartMobile.png';
import BaggageAnimation from "../BaggageAnimation";
import bottomIcons from './bottomIcons.png';
class MainContent extends React.Component {

    constructor(props){
        super(props);

        this.state = {showResult: false, result: false};
    }

    onSubmitButtonClick = (e) => {
        const {name, email} = this.state;
        this.setState({name: "", email: ""});

        if(!name || !email || name.length < 0 || email.length < 1){
            this.setState({result: false, showResult: true});
            this.showResult();
            return;
        }

        if(localStorage.getItem('posted')){
            return;
        }

        const data = {name: name, email: email};

        fetch('/emailSending', {
            method: 'POST',
            body: JSON.stringify(data),
            })
            .then((response) => {
                if (response.status !== 200) {
                    // make the promise be rejected if we didn't get a 200 response
                    throw new Error("Not 200 response")
                } else {
                    console.log("It;s ok");
                    localStorage.setItem('posted', "true");
                    this.setState({result: true, showResult: true});
                    this.showResult();
                }
            })
            .catch((e) => {
                this.setState({result: false, showResult: true});
                this.showResult();
            });
    };

    showResult = () => {
        setTimeout(() => {
            this.setState({showResult: false});
        }, 2000)
    };

    onValueChange = (e) => {
      this.setState({[e.target.name]: e.target.value})
    };

    render() {
        return (
            <div className="main-content">
                <div className="main-content-container-top">
                    <div className={'main-content-top-part'}>
                        <CarryAirLogo big={false} poweredBy={false} />
                        <p className={'main-content-header'}>Next-Generation Customer Experience for Innovative Airlines</p>
                        <p className={'main-content-normal-text'}>Web-based augmented reality experience for passengers to measure and upgrade luggage at home with no app installation.</p>
                    </div>

                </div>
                <div className="main-content-container-bottom">
                    <div className={'main-content-bottom-list'}>
                        <ul>
                            <li>Measure</li>
                            <li>Compare</li>
                            <li>Upgrade</li>
                        </ul>
                    </div>
                    <SendEmailForm
                        onSubmitButtonClick={this.onSubmitButtonClick.bind(this)}
                        onValueChanged={this.onValueChange.bind(this)}
                        showResult={this.state.showResult}
                        result={this.state.result}
                    />
                    <img src={plane} alt="" className={'main-content-bottom-plane-path'}/>
                    <img src={bottomPartMobile} alt="" className={'main-content-bottom-part-mobile'}/>
                    <p className={'main-content-footer'}>Web • Mobile • Augmented Reality • Smart travel upgrade</p>
                </div>
                <div className={'main-content-side-image-container'}>
                    <BaggageAnimation />
                    <img src={bottomIcons} alt="" className={'main-content-side-image'}/>
                </div>
            </div>
        );
    }
}

export default MainContent;
