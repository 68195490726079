import React, { Component } from "react";
import * as THREE from "three";
import './styles.css';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import baggageGrey from './CarryOn_HandLaggage_Arrows Gray.glb';
import baggageRed from './CarryOn_HandLaggage_Arrows.glb';

class BaggageAnimation extends Component {
    componentDidMount() {
        this.initializeThreeJS();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.isCreated && !this.state.loadedStarted){
            this.generateModels()
                .then(res => {
                    this.setState({isCreated: true, baggageGrayModel: res.baggageGrayModel, baggageRedModel: res.baggageRedModel, canMove: true});
                })
                .catch(error => {
                    console.error(error);
                })
        }
    }

    initializeThreeJS = () => {
        let scene = new THREE.Scene();
        let camera = new THREE.PerspectiveCamera( 60, window.innerWidth/window.innerHeight, 0.1, 1000 );
        let renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
        renderer.setClearColor( 0x000000, 0 );
        this.mount.appendChild( renderer.domElement );
        renderer.setSize(renderer.domElement.parentElement.offsetWidth, renderer.domElement.parentElement.offsetHeight);

        window.addEventListener('resize', this.onWindowResize, false);
        window.addEventListener('mousemove', this.onMouseMove);
        this.setState({renderer: renderer, camera: camera, scene: scene});
    };

    generateModels = () => {
        const {camera, renderer, scene} = this.state;

        const light = new THREE.AmbientLight( 0xD2D1D3 ); // soft white light
        scene.add( light );

        const animateBaggage = this.animateBaggage;

        camera.position.z = 1;
        let animate = function () {
            requestAnimationFrame( animate );
            renderer.render( scene, camera );

            animateBaggage();
        };
        animate();

        this.onWindowResize();
        this.setState({loadedStarted: true});
        return new Promise(((resolve, reject) => {
            let baggageGrayModel, baggageRedModel;
            const loader = new GLTFLoader();
            loader.load( baggageGrey, function ( gltf ) {
                scene.add( gltf.scene );
                baggageGrayModel = gltf.scene;
                baggageGrayModel.position.y = -0.3;

                loader.load( baggageRed, function ( gltf ) {
                        scene.add( gltf.scene );
                        baggageRedModel = gltf.scene;
                        baggageRedModel.position.y = -0.3;
                        baggageRedModel.scale.set(1.2, 1.2, 1.2);
                        resolve({baggageGrayModel: baggageGrayModel, baggageRedModel:baggageRedModel});
                    },
                    undefined, function ( error ) {
                        reject(error);
                    });
            }, undefined, function ( error ) {
                reject(error);
            } );
        }));
    };

    onWindowResize = () => {
        const {camera, renderer} = this.state;
        const parentElement = renderer.domElement.parentElement;

        camera.aspect = parentElement.offsetWidth / parentElement.offsetHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(parentElement.offsetWidth, parentElement.offsetHeight);
    };

    onMouseMove = (e) => {
        if(!this.state.clientX){
            this.setState({clientX: e.clientX});
        }

        const lastClientX = this.state.clientX;
        const delta = e.clientX - lastClientX;
        const {baggageGrayModel, baggageRedModel} = this.state;

        if(baggageGrayModel && baggageRedModel){
            if(this.state.animationEnabled){
                baggageGrayModel.rotateY(delta/500);
                baggageRedModel.rotateY(delta/500);
            }
        }

        this.setState({clientX: e.clientX});
    };

    animateBaggage = () => {
        const {baggageGrayModel, baggageRedModel} = this.state;

        if(baggageGrayModel && baggageRedModel){
            baggageGrayModel.rotateY(1/500);
            baggageRedModel.rotateY(1/500);
        }
    };

    onBaggageAnimationClick = (e) => {
        this.setState({animationEnabled: true});
    };

    onBaggageAnimationUp = () => {
        this.setState({animationEnabled: false});
    };

    render() {
        return (
            <div ref={ref => (this.mount = ref)}
                 className={'baggage-animation-container'}
                 onMouseDown={this.onBaggageAnimationClick.bind(this)}
                 onMouseLeave={this.onBaggageAnimationUp.bind(this)}
                 onMouseUp={this.onBaggageAnimationUp.bind(this)}
            />
        )
    }
}

export default BaggageAnimation;